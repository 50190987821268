<template>
  <div>
    <div v-if="tags.data && tags.data.length" class="position-table">
      <md-table
        v-model="tags.data"
        :md-sort-fn="customSort"
        :md-sort.sync="customSortBy"
        :md-sort-order.sync="customSortOrder"
      >
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="ID" md-sort-by="id" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Название"
            md-sort-by="title"
            class="custom-field"
          >
            <span>{{ item.title }}</span>
          </md-table-cell>

          <md-table-cell md-label="Ключ" md-sort-by="key" class="custom-field">
            <span>{{ item.key }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Порядок"
            md-sort-by="order"
            class="custom-field"
          >
            <span>{{ item.order }}</span>
          </md-table-cell>

          <md-table-cell>
            <div class="edit-and-delete-block">
              <md-button
                class="md-just-icon md-simple md-primary"
                :to="`/tags/${item.id}`"
              >
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="top">Редактировать</md-tooltip>
              </md-button>

              <md-button
                class="md-just-icon md-simple md-danger"
                @click="selectForRemove(item.id)"
              >
                <md-icon>delete</md-icon>
                <md-tooltip md-direction="top">Удалить</md-tooltip>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="tags.last_page > 1" class="pagination">
        <md-button
          class="md-primary"
          :disabled="tags.current_page === 1"
          @click="$emit('prev')"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <div class="pages">
          <span>{{ tags.current_page }}</span>
          /
          <span>{{ tags.last_page }}</span>
        </div>
        <md-button
          class="md-primary"
          :disabled="tags.current_page === tags.last_page"
          @click="$emit('next')"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </div>
    <div v-else class="my-empty empty">Пусто</div>

    <ModalConfirm
      v-if="showModal"
      text="Удалить тег?"
      @confirm="deleteTag"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/Modals/Confirm";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    ModalConfirm,
  },

  data() {
    return {
      customSortBy: "id",
      customSortOrder: "asc",
      selectedId: null,
      showModal: false,
    };
  },

  computed: {
    ...mapState({
      tags: (state) => state.tags.tags,
    }),
  },

  methods: {
    ...mapActions("tags", ["removeTag"]),

    customSort(value) {
      this.$emit("sort", {
        sortBy: this.customSortBy,
        sortOrder: this.customSortOrder,
      });
      return value;
    },

    async deleteTag() {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.removeTag(this.selectedId);
      if (success) {
        this.$emit("sort", {
          sortBy: this.customSortBy,
          sortOrder: this.customSortOrder,
        });

        this.selectedId = null;
        this.showModal = false;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    selectForRemove(id) {
      this.selectedId = id;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-sortable-icon {
    left: unset !important;
    right: 8px !important;
  }

  .md-table-row {
    cursor: pointer;
  }
}
</style>
